<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Proje Tanımları"
            icon="mdi-home-roof"
            :add-route="
              can('edit-project')
                ? { name: 'other-definitions.projects.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-project')"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'other-definitions.projects.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.title="{ item }">
          <router-link
            :to="{
              name: 'other-definitions.projects.show',
              params: { id: item.id },
            }"
          >
            {{ item.title }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.starts_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.ends_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.income="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.expense="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.diff="{ value }">
          <rs-table-cell-number price colored :value="value" />
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-project"),
        },
        {
          text: "Proje Adı",
          value: "title",
          searchable: "text",
        },
        {
          text: "Proje Kodu",
          value: "name",
          searchable: "text",
        },
        {
          text: "Başlangıç Tarihi",
          value: "starts_on",
          searchable: "date",
        },
        {
          text: "Bitiş Tarihi",
          value: "ends_on",
          searchable: "date",
        },
        {
          text: this.$t("labels.amount"),
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Proje Gelir",
          value: "income",
          searchable: "number",
          align: "end",
        },
        {
          text: "Proje Gider",
          value: "expense",
          searchable: "number",
          align: "end",
        },
        {
          text: "Proje Fark",
          value: "diff",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          sortable: true,
          align: "center",
        },
        {
          text: null,
          value: "actions",
          searchable: false,
          sortable: false,
          align: "right",
        },
      ],
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      showFirstBalanceForm: false,
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`clusters/${this.clusterId}/projects`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "other-definitions.projects.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
  },
};
</script>
