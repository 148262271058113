var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.isLoading}},[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"fixed-header":false,"height":undefined,"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',{attrs:{"title":"Proje Tanımları","icon":"mdi-home-roof","add-route":_vm.can('edit-project')
              ? { name: 'other-definitions.projects.create' }
              : null,"hide-edit":!_vm.can('edit-project'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}}):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [(_vm.list.length > 0)?_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}}):_vm._e()]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'other-definitions.projects.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.title",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'other-definitions.projects.show',
            params: { id: item.id },
          }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.starts_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.ends_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.is_active",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.income",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.expense",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.diff",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","colored":"","value":value}})]}}],null,false,1588045192),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }